const APP_V     = "1.0.3";
var RC          = null;
var SC          = null;
var PW          = null;
var DYLOG_URL   = "https://restgate1.dylog.it:9191/RestaurantService.svc/XML/SUBSCRIBERS/#username#/RESTAURANTS/#rest_code#/GETBILL";
var MIDD_URL    = "http://217.160.159.28:80";//"http://217.160.159.28:80";
var WS_URL      = "http://217.160.159.28:9098";
var B_AUTH      = "";
var IN_DEBUG    = true;
var STATUS      = [];
var ACT_LIST    = false;
var IN_MODIF    = false;
var DEV_ID      = -1;
var PRDS        = [];
var DEV_PRDS    = [];
var INIT_ALL    = false;
var EXT_CODES   = [];
var STAT_NOV    = [];
var QTA_MNG     = [];
var WKP_NOV     = [];
var JOIN_CMD    = [];
var COVERS      = [];
var IS_FS       = false;
var AUDIO       = null;
var PLAY_AUDIO  = false;
const VERSION   = "1";
var N_OF_DISH   = -1;
var CARRY       = [];
var DEVS        = [];
var NOREP       = [];
var ALERTS      = [];
var DEL_ALERT   = null;
var F_RENDER    = null;
var N_VAI       = -1;
var TXNS        = null;
var ALERT_RUN   = false;
var GROUP_COLOR = null;

// CHAT
var HAS_CHAT            = false;
var ID_CHAT             = -1;
var DEL_MSG_TO_READ     = null;
var ADD_MSG             = null;
var INSTANCE_CHAT       = null;
var MESSAGES            = [];
var DEL_OVERRIDE_MSG    = null;
var DEL_ALL_CHATS       = null;

const set_rc        = (value) => RC             = value;
const set_sc        = (value) => SC             = value;
const set_pw        = (value) => PW             = value;
const set_du        = ()      => DYLOG_URL      = DYLOG_URL.split("#username#").join(SC).split("#rest_code#").join(RC);
const set_ba        = (value) => B_AUTH         = value;
const set_status    = (value) => STATUS         = value;
const set_act_list  = (value) => ACT_LIST       = value;
const set_in_modif  = (value) => IN_MODIF       = value;
const set_dev_id    = (value) => DEV_ID         = value;
const set_prds      = (value) => PRDS           = value;
const set_dev_prds  = (value) => DEV_PRDS       = value;
const set_init_all  = (value) => INIT_ALL       = value;
const set_ext_codes = (value) => EXT_CODES      = value; 
const set_stat_nov  = (value) => STAT_NOV       = value;
const set_wkp_nov   = (value) => WKP_NOV        = value;
const set_is_fs     = (value) => IS_FS          = value;
const set_audio     = (value) => AUDIO          = value;
const set_n_of_dish = (value) => N_OF_DISH      = value;
const set_pl_audio  = (value) => PLAY_AUDIO     = value;
const set_covers    = (value) => COVERS         = value;
const set_glb_carry = (value) => CARRY          = value;
const set_devs      = (value) => DEVS           = value;
const set_noreps    = (value) => NOREP          = value;
const f_render      = (value) => F_RENDER       = value;
const set_n_vai     = (value) => N_VAI          = value;
const set_alert     = (value) => ALERTS         = value;
const set_del_alert = (value) => DEL_ALERT      = value;
const set_glb_txns  = (value) => TXNS           = value;
const set_alert_run = (value) => ALERT_RUN      = value;
const set_group_clr = (value) => GROUP_COLOR    = value;

// CHAT
const set_has_chat          = (value) => HAS_CHAT           = value;
const set_id_chat           = (value) => ID_CHAT            = value;
const set_del_msg_to_read   = (value) => DEL_MSG_TO_READ    = value;
const set_add_msg           = (value) => ADD_MSG            = value;
const set_chat_instance     = (value) => INSTANCE_CHAT      = value;
const set_messages          = (value) => MESSAGES           = value;
const set_del_override_msg  = (value) => DEL_OVERRIDE_MSG   = value;
const set_del_all_chats     = (value) => DEL_ALL_CHATS      = value;

const groupBy = (array, property) => {

    let tmp = [];

    array.forEach(a => {
        const props = a[property] ? a[property].toString() : "null";
        const index = tmp.findIndex(t => t.key == props);
        if(index == -1){
            tmp.push({key: props, values: [a]});
        }else{
            tmp[index].values.push(a);
        }
    });

    return tmp;

};

const inSameCommand = (d1, d2) => {
    try{
        const _d1 = (new Date(d1)).getTime();
        const _d2 = (new Date(d2)).getTime();
        const diff = Math.abs( (((_d1 - _d2) / 1000) / 60) );
        return diff <= 2;
    }catch(_){
        return false;
    }
};

export {
    APP_V, DYLOG_URL, RC, SC, PW, B_AUTH, MIDD_URL, IN_DEBUG, STATUS, ACT_LIST, IN_MODIF, DEV_ID, PRDS, DEV_PRDS, VERSION, INIT_ALL, EXT_CODES, STAT_NOV, WKP_NOV, IS_FS, QTA_MNG, AUDIO, N_OF_DISH, PLAY_AUDIO, JOIN_CMD, COVERS, CARRY, DEVS, NOREP, F_RENDER, N_VAI, WS_URL, ALERTS, DEL_ALERT, TXNS, ALERT_RUN, GROUP_COLOR, HAS_CHAT, ID_CHAT, DEL_MSG_TO_READ, ADD_MSG, INSTANCE_CHAT, MESSAGES, DEL_OVERRIDE_MSG, DEL_ALL_CHATS,
    set_rc, set_sc, set_pw, set_du, set_ba, set_status, set_act_list, set_in_modif, set_dev_id, set_prds, set_dev_prds, set_init_all, set_ext_codes, set_stat_nov, set_wkp_nov, set_is_fs, set_audio, set_n_of_dish, set_pl_audio,
    groupBy, inSameCommand, set_covers, set_glb_carry, set_devs, set_noreps, f_render, set_n_vai, set_del_alert, set_alert, set_glb_txns, set_alert_run, set_group_clr, set_has_chat, set_id_chat, set_del_msg_to_read, set_add_msg, set_chat_instance, set_messages, set_del_override_msg, set_del_all_chats
}