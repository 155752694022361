import { click } from "@testing-library/user-event/dist/click";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IN_DEBUG, NOREP, QTA_MNG, STATUS } from "../glb";
import { epson, esc_pos } from "../printers/protocols";
import { set_done } from "../reuse";

let clicked_on_flip = false;
export default function Dish({item, double_click, tap, active, remove_from_active, callback_for_re_rendering, txns, multiple_taps}){

    const [start_pulse, set_start_pulse]    = useState(false);
    const [is_flipped, set_is_flipped]      = useState(false);
    const [color, set_color]                = useState("#fff");
    const [text_color, set_text_color]      = useState('#000000');
    const navigate                          = useNavigate();

    const pulse = (e) => {
        const arr_cards = e.detail ?? [];
        const index = arr_cards.findIndex(a => a.id == item.id);
        set_start_pulse(index >= 0);
    };

    const formatDate = (timestamp) => {

        let timestamp_ = "01-01-00001 00:00:00";

        try{
            const d = new Date(timestamp);
            timestamp_ = `${d.getDate().toString().padStart(2,'0')}/${ (d.getMonth() + 1).toString().padStart(2, '0') }/${d.getFullYear().toString()} ${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}:${d.getSeconds().toString().padStart(2, '0')}`;
        }catch(e){
            console.log(e);
        }

        return timestamp_;
    };

    const info_event = (item) => {

        clicked_on_flip = true;
        const div = document.querySelector('#body_modal');

        let html = "";
        if(item.note){
            html += "<h3>Nota</h3>";
            html += "<p>" + item.note + "</p><hr/>";
        }
        if(item.vai_con){
            html += "<h3>Info sull'uscita</h3>";
            html += "<p>" + item.vai_con + "</p>";
            const filtered_tables = txns.filter(i => i.id == item.table_code);
            if(filtered_tables.length > 0){
                const filtered_lines = filtered_tables[0].lines.filter(i => i.vai_con == item.vai_con && i.id !== item.id);
                let vai_con_html = "";
                filtered_lines.forEach(l => vai_con_html += "<p>" + l.description + "</p>");
                html += (vai_con_html ? "<span style='color: red; font-weight: bold;'>con</span> " : "") + vai_con_html;
            }
            html += "<hr/>";
        }
        if(item.code_waiter || item.desc_waiter){
            html += "<h3>Cameriere</h3>";
            html += (item.code_waiter ? item.code_waiter : "") + " " + (item.desc_waiter ? item.desc_waiter : "");
            html += "<hr/>";
        }

        div.innerHTML = html;
        window.location.hash = "#msg";

    };

    const modal_event = (item) => {
        if(item.quantity > 1 && item.status == "da_fare"){
            clicked_on_flip = true;
            const div = document.querySelector('#body_modal');
            if(div){
                div.innerHTML = `
                    <div style="padding: 10px;">
                        <input class='form-control' placeholder="Numero di piatti lavorati" id="input_dish" disabled></input>
                        <div style="display: flex; justify-content: space-between; gap: 10px; margin-top: 10px;">
                            <button class="btn btn-outline-success btn_volatile" style="width: 100%; height: 70px;">1</button>
                            <button class="btn btn-outline-success btn_volatile" style="width: 100%; height: 70px;">2</button>
                            <button class="btn btn-outline-success btn_volatile" style="width: 100%; height: 70px;">3</button>
                        </div>
                        <div style="display: flex; justify-content: space-between; margin-top: 10px; gap: 10px">
                            <button class="btn btn-outline-success btn_volatile" style="width: 100%; height: 70px;">4</button>
                            <button class="btn btn-outline-success btn_volatile" style="width: 100%; height: 70px;">5</button>
                            <button class="btn btn-outline-success btn_volatile" style="width: 100%; height: 70px;">6</button>
                        </div>
                        <div style="display: flex; justify-content: space-between; margin-top: 10px; gap: 10px">
                            <button class="btn btn-outline-success btn_volatile" style="width: 100%; height: 70px;">7</button>
                            <button class="btn btn-outline-success btn_volatile" style="width: 100%; height: 70px;">8</button>
                            <button class="btn btn-outline-success btn_volatile" style="width: 100%; height: 70px;">9</button>
                        </div>
                        <div style="display: flex; justify-content: space-between; margin-top: 10px; gap: 10px">
                            <button class="btn btn-outline-success  btn_volatile" style="width: 100%; height: 70px;">0</button>
                            <button class="btn btn-outline-danger  btn_volatile" style="width: 100%; height: 70px; font-size: 30px;">«</button>
                        </div>
                        <button class="btn btn-success" style="width: 100%; margin-top: 10px; background: #00A841; height: 50px;" id="confirm_dish">Conferma piatti</button>
                        <button class="btn btn-success" style="width: 100%; margin-top: 10px; background: #00A841; height: 50px;" id="confirm_dish_and_print">Conferma piatti e stampa</button>
                    </div>
                `;
            }
            window.location.hash = "#msg";
            
            const buttons = document.getElementsByClassName('btn_volatile');
            for(let btn of buttons){
                btn.addEventListener('click', (e) => {
                    const input = document.querySelector('#input_dish');
                    if(e.target.textContent == "«"){
                        if(input.value.length == 1){
                            input.value = "";
                        }else{
                            if(input.value.length > 1){
                                input.value = input.value.substr(0, input.value.length - 1);
                            }
                        }
                    }else{
                        input.value = input.value + e.target.textContent;
                    }
                });
            }

            const confirm_dish = (input) => {
                if(input && !isNaN(input)){
                    if( (parseInt(input) + item.done) <= item.quantity ){
                        const done = parseInt(input) + item.done;
                        set_done(item.id, done);
                        item.done = done;
                        const index = QTA_MNG.findIndex(q => q.id == item.id);
                        if(index > -1){
                            QTA_MNG[index].quantity = done;
                        }else{
                            QTA_MNG.push({id: item.id, quantity: done});
                        }
                        navigate(-1);
                        callback_for_re_rendering();
                        return true;
                    }else{
                        alert("Attenzione, quantità lavorate maggiori di quelle disponibili!");
                    }
                }else{
                    alert("Numero di piatti non valido!");
                }
                return false;
            };

            document.querySelector('#confirm_dish').addEventListener('click', () => {
                const input = document.querySelector('#input_dish').value;
                confirm_dish(input);
            });

            document.querySelector('#confirm_dish_and_print').addEventListener('click', () => {
                const input = document.querySelector('#input_dish').value;
                if(confirm_dish(input)){
                    esc_pos([
                        `TAVOLO ${item.table_code}`,
                        "************************",
                        `x${input} ${item.description}`,
                        "************************",
                    ])
                }
            });

            setTimeout(() => {
                clicked_on_flip = false;
            }, 500);
        }
    };

    useEffect(() => {

        window.addEventListener('bc:pulse', (e) => {
            pulse(e);
        });

        return (() => {
            try{
                window.removeEventListener('bc:pulse', pulse);
            }catch(_){}
        });

    }, []);

    useEffect(() => {

        if(item){
            if(item.status !== "da_fare"){
                
                if(item.work_in_progress){
                    item.work_in_progress = false;
                }

                const filtered = (STATUS ?? []).filter(i => i.status_enum == item.status);
                if(filtered && filtered.length > 0){
                    set_color(filtered[0].hex_color);
                    set_text_color(filtered[0].text_hex_color);
                }
            }else{
                set_color("#fff");
                set_text_color('#000000');
                
            }
        }

    }, [item]);

    return (
        localStorage.getItem('monitor') == 'dish' ? 
            <>
                <div class={`flip-card ${is_flipped ? 'flipped' : ''}`}>
                    <div class="flip-card-inner">

                        {
                            // Front
                        }
                        <div class={`card ${active ? (item.work_in_progress && item.status == 'da_fare' ? 'selected-in-progress' : 'selected') : ''} ${item.work_in_progress && item.status == 'da_fare' ? 'in-progress' : (item.quantity > 1 && item.done >= 1 && item.status == 'da_fare' ? 'in-progress-with-done' : '')}`}  style={{background: color}} onClick={() => {
                            if(clicked_on_flip){
                                clicked_on_flip = false;
                                return;
                            }
                            tap();
                        }}>
                            {
                                item.menu_composition || item.variations ? 
                                    <div onClick={() => {
                                        
                                        window.location.hash += "#msg";
                                        clicked_on_flip = true;
                                        
                                        const div = document.querySelector('#body_modal');
                                        div.innerHTML = "";
                                        let menu = null;
                                        let vars = null;

                                        let html = "<div><h4>Composizione menu</h4></div>";
                                        if(item.menu_composition){
                                            menu = JSON.parse(item.menu_composition);
                                        }else{
                                            html += "<div>Nessuna composizione</div>";
                                        }

                                        if(menu && menu.length > 0){
                                            menu.forEach((item) => {
                                                html += "<div style='font-size: 20px'>" + item.menu_name + "</div>";
                                            });
                                        }

                                        html += "<div style='margin-top: 10px; border-top: 1px solid rgb(220, 220, 220)'><h4 style='margin-top: 10px'>Varianti</h4></div>";
                                        if(item.variations){
                                            vars = JSON.parse(item.variations);
                                        }

                                        if(vars && vars.length > 0){
                                            vars.forEach((item) => {
                                                html += "<div style='font-size: 20px'>" + item.variant_name + "</div>";
                                            });
                                        }else{
                                            html += "<div>Nessuna variante</div>";
                                        }

                                        div.innerHTML = html;

                                    }} style={{color: text_color, cursor: "pointer"}}>
                                        {
                                            //<i class="fas fa-exchange-alt flip-card-icon" aria-hidden="true"></i>
                                        }
                                        {
                                            //<p class="flip-card-icon pulse" style={{color: "red", fontWeight: "bold", fontSize: "20px"}}>VR</p>
                                        }
                                        <i className="fas fa-plus flip-card-icon pulse" style={{color: "red"}}/>
                                    </div>
                                :
                                    <></>
                            }
                            <div class="card-header" style={{background: color, color: text_color}}> 
                                <p onClick={() => {
                                    if(item.note || item.vai_con || item.code_waiter || item.desc_waiter){
                                        info_event(item);
                                    }
                                }} style={{cursor: "pointer"}}>
                                    {item.ext_code ? item.ext_code : ".."}
                                    {
                                        item.note || item.vai_con || item.code_waiter || item.desc_waiter ? 
                                            <>&nbsp;<i class="fas fa-info-circle"></i></>
                                        :
                                            <></>
                                    }
                                </p>
                                <p>{item.description}</p>
                                {
                                    start_pulse ? 
                                        <div className="notification pulse"></div>
                                    :
                                        <></>
                                }
                            </div>
                            <div class="card-body" style={{background: color, color: text_color, display: localStorage.getItem('monitor') == 'table' ? "none" : "flex", justifyContent: "space-between"}}> 
                                <>
                                    <div>T.{item.table_code}</div>
                                    <div style={{color: "red", cursor: "pointer", fontSize: "20px", visibility: item.quantity > 1 && item.status == "da_fare" ? "" : "hidden"}} onClick={() => {
                                        modal_event(item);
                                    }}>
                                        x{item.done}
                                    </div>
                                </>
                            </div>
                            <div class="card-footer" style={{background: color}}> 
                                {
                                    localStorage.getItem('monitor') == 'table' ? 
                                        <p style={{visibility: "hidden"}}>..</p>
                                    :
                                        <>
                                            <p style={{color: parseInt(item.elapsed_minutes) > 10 ? "red" : "black"}}><i class="fas fa-clock"></i>&nbsp;{item.elapsed_minutes} '</p>
                                        </> 
                                }
                                <p style={{color: 'var(--green)', fontWeight: "bold"}}>{item.quantity > 0 ? `x${item.quantity}` : "x0"}</p>
                            </div>
                        </div>

                        {
                            // Back
                        }
                        {
                            is_flipped ?
                                <div class="card-back" onClick={() => set_is_flipped(false)}>
                                    <i class="fas fa-exchange-alt flip-card-icon" aria-hidden="true"></i>
                                    {
                                        (STATUS ?? []).map((item, index) => 
                                            <div class="card-back-status" key={"status_" + index.toString()} onClick={() => {
                                                if(!is_flipped) return;
                                                set_is_flipped(false);
                                                double_click(item.status_enum);
                                            }}>
                                                {item.status_label}
                                            </div>
                                        )
                                    }

                                </div>
                            :
                                <></>
                        }

                    </div>
                </div>
            
            </>
        :
            <>  
                {
                    item.vai_con && item.display_vai_con && item.status == "da_fare" && !item.is_carry ? 
                        (!item.is_carry || (item.is_carry && !NOREP.includes(item.bill_id))) ? 
                            <div style={{marginLeft: "10px", color: "red", fontWeight: "bold", fontSize: "16px", borderRadius: "10px", marginTop: "2px"}} onClick={() => {
                                if(item.is_carry) return;
                                const filteredTxns = txns.filter(t => t.bill_id == item.bill_id);
                                if(filteredTxns && filteredTxns.length > 0){
                                    const lines = filteredTxns[0].lines.filter(i => i.status == item.status && item.vai_con == i.vai_con && item.nsessione == i.nsessione);
                                    if(lines && lines.length > 0){
                                        if(multiple_taps){
                                            multiple_taps(lines);
                                        }
                                    }
                                }
                            }}>&nbsp;{item.vai_con}</div>
                        :
                            <></>
                        
                    :
                        <></>
                }
                <div className={`table-dish ${active && !item.is_carry ? (item.work_in_progress && item.status == 'da_fare' ? 'selected-in-progress' : 'selected') : ''} ${item.work_in_progress && item.status == 'da_fare' ? 'in-progress' : (item.quantity > 1 && item.done >= 1 && item.status == 'da_fare' ? 'in-progress-with-done' : '')}`} style={{paddingLeft: "10px", paddingRight: "10px", fontSize: item.is_carry ? "12px" : ( (localStorage.getItem("line_font_size") ?? "16") + "px"), fontWeight: item.is_carry ? "bold" : "", fontStyle: item.is_carry ? "italic" : "", display: (!item.is_carry || (item.is_carry && !NOREP.includes(item.bill_id))) ? "flex" : "none", background: item.menu_parent ? "#c0f7c0" : (item.highlight && item.status == "da_fare" && localStorage.getItem('highlight_dish') == "1" ? "#73dfff94" : "unset"), color: item.menu_code ? "red" :"#000"}} onClick={() => {
                    if(item.is_carry) return;
                    if(clicked_on_flip){
                        clicked_on_flip = false;
                        return;
                    }
                    tap();
                }}>
                    <div style={{fontWeight: "600", width: "10%", textAlign: "left"}}>
                        {item.quantity}x
                    </div>
                    <div style={{width: "15%", textAlign: "left", display: localStorage.getItem('external_code') == "1" ? "" : "none"}}>
                        {(item.ext_code ?? "").toString()}
                    </div>
                    <div style={{textAlign: "left", width: "65%", whiteSpace: item.is_carry ? "nowrap" : "", textOverflow: item.is_carry ? "ellipsis" : "", overflow: item.is_carry ? "hidden" : "", display: localStorage.getItem('active_critical_command') == "1" ? "flex" : "block", alignItems: "center"}}>
                        {item.description} {item.is_carry ? <span style={{color: "var(--green)"}}>(da {item.carry_device})</span> : ``} <span style={{color: "red"}}>{item.is_carry ? `${item.vai_con ? `(${item.vai_con})` : ``}` : ""}</span>
                        {item.evasione_vai && item.status == "da_fare" ? <span className="pulse" style={{color: "red", fontWeight: "bold"}}>&nbsp;&nbsp;VAI ({item.elapsed_min_from_vai ? (item.elapsed_min_from_vai + "'") : (item.desc_evasione.split("T")[1].substring(0, 5))})</span> : <></>}
                        {
                            (item.note || (item.variations && JSON.parse(item.variations).length)) ? 
                                <span style={{color: "red"}} onClick={() => {
                                    clicked_on_flip = true;
                                    const obj = document.querySelector(`#detail_dish_${item.id}`);
                                    obj.style.display = obj.style.display == "none" ? "" : "none";
                                    document.querySelector('#fas_open_dish_row_' + item.id).className = (obj.style.display == "none" ? "fas fa-plus" : "fas fa-minus");
                                }}>&nbsp;&nbsp;<i class={localStorage.getItem('opened_variants') == "1" ? "fas fa-minus" : "fas fa-plus"} id={"fas_open_dish_row_" + item.id}></i></span>
                            :
                                <></>
                        }
                        {
                            item && item.contosepid && localStorage.getItem('sub_bill') == "1" ? 
                                <span style={{fontWeight: "bold"}}>&nbsp;[{item.contosepid}]</span>
                            :
                                <></>
                        }
                        {
                            localStorage.getItem('active_critical_command') == "1" && item && item.override_color ? 
                                <div style={{background: item.override_color, width: "16px", height: "16px", borderRadius: "10000px", marginLeft: "10px"}} className="pulse"></div>
                            :
                                <></>
                        }
                        {
                            item.menu_composition || item.variations ? 
                                <div style={{height: "16px", marginLeft: "10px"}} onClick={() => {

                                    window.location.hash += "#msg";
                                    clicked_on_flip = true;
                                    
                                    const div = document.querySelector('#body_modal');
                                    div.innerHTML = "";
                                    let menu = null;
                                    let vars = null;

                                    let html = "<div><h4>Composizione menu</h4></div>";
                                    if(item.menu_composition){
                                        menu = JSON.parse(item.menu_composition);
                                    }else{
                                        html += "<div>Nessuna composizione</div>";
                                    }

                                    if(menu && menu.length > 0){
                                        menu.forEach((item) => {
                                            html += "<div style='font-size: 20px'>" + item.menu_name + "</div>";
                                        });
                                    }

                                    html += "<div style='margin-top: 10px; border-top: 1px solid rgb(220, 220, 220)'><h4 style='margin-top: 10px'>Varianti</h4></div>";
                                    if(item.variations){
                                        vars = JSON.parse(item.variations);
                                    }

                                    if(vars && vars.length > 0){
                                        vars.forEach((item) => {
                                            html += "<div style='font-size: 20px'>" + item.variant_name + "</div>";
                                        });
                                    }else{
                                        html += "<div>Nessuna variante</div>";
                                    }

                                    div.innerHTML = html;

                                }}>
                                    <i className="fas fa-plus flip-card-icon pulse" style={{color: "red", fontSize: "20px"}}/>
                                </div>
                            :
                                <></>
                        }
                    </div>
                    <div style={{width: "10%", textAlign: "right", fontWeight: "600"}} onClick={() => {
                        if(item.is_carry) return;
                        if(item.quantity){
                            clicked_on_flip = true;
                            modal_event(item);
                        }
                    }}>
                        {
                            item.quantity > 1 ? 
                                item.done
                            :
                                <></>
                        }
                    </div>
                </div>
                <div style={{background: "white", color: "black", display: localStorage.getItem('opened_variants') == "1" ? "" : "none"}} id={`detail_dish_${item.id}`}>
                    {
                        item.note ? 
                            <p className="dish-note">
                                {
                                    item.note
                                }
                            </p>
                        :
                            <></>
                    }
                    {
                        item.variations && JSON.parse(item.variations).length > 0 ? 
                            <ul className="dish-variations" style={{
                                fontSize: ( (localStorage.getItem("line_font_size") ?? "12") + "px")
                            }}>
                                {
                                    JSON.parse(item.variations).map((variation, index_var) => 
                                        <li style={{
                                            lineHeight: ( (localStorage.getItem("line_font_size") ?? "15") + "px")
                                        }} key={`variation_dish_${item.id}_${index_var}`}>{variation.variant_name}</li>
                                    )
                                }
                            </ul>
                        :
                            <></>
                    }
                    {
                        item.menu_composition && JSON.parse(item.menu_composition ?? "[]").length > 0 && (localStorage.getItem('explode_menu') == "1") ? 
                            <ul className="dish-variations" style={{
                                fontSize: ( (localStorage.getItem("line_font_size") ?? "12") + "px")
                            }}>
                                {
                                    JSON.parse(item.menu_composition).map((menu, index_var) => 
                                        <li style={{
                                            lineHeight: ( (localStorage.getItem("line_font_size") ?? "15") + "px")
                                        }} key={`vmenu_dish_${item.id}_${index_var}`}>{menu.menu_name}</li>
                                    )
                                }
                            </ul>
                        :
                                <></>
                    }
                    {
                        item.corrections && item.corrections.length > 0 ? 
                            <div className="corrections">
                                {
                                    item.corrections.map((correction, index) => 
                                        <div key={`correction_${index}`} style={{display: "flex", marginLeft: "10px", alignItems: "center"}}>
                                            <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-alarm" viewBox="0 0 16 16">
                                                    <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9z"/>
                                                    <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1zm1.038 3.018a6 6 0 0 1 .924 0 6 6 0 1 1-.924 0M0 3.5c0 .753.333 1.429.86 1.887A8.04 8.04 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5M13.5 1c-.753 0-1.429.333-1.887.86a8.04 8.04 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1"/>
                                                </svg>
                                                {formatDate(correction.updated_at)} /
                                            </div>
                                            <div style={{display: "flex", alignItems: "center", gap: "5px", marginLeft: "10px"}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                                                </svg>
                                                {correction.waiter} /
                                            </div>
                                            <div style={{display: "flex", alignItems: "center", gap: "5px", marginLeft: "10px", color: "red", fontWeight:"bold", fontSize: "18px"}}>
                                                {correction.old_quantity}x 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
                                                </svg>
                                                {correction.new_quantity}x
                                            </div>
                                        </div>
                                    )
                                }
                            </div> 
                        : 
                            <></>
                    }
                </div>
            </>
    );
}